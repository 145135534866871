import {ID, Response} from '../helpers'

export type SendMessage = {
  message?: string
  phone?: string
  sender?: string
}

var KycStatus
;(function (KycStatus) {
  KycStatus[(KycStatus['unverified'] = 0)] = 'unverified'
  KycStatus[(KycStatus['verified'] = 2)] = 'verified'
  KycStatus[(KycStatus['rejected'] = 99)] = 'Rejected'
})(KycStatus || (KycStatus = {}))

export {KycStatus}

var KycStatusStyle
;(function (KycStatusStyle) {
  KycStatusStyle[(KycStatusStyle['dark'] = 0)] = 'dark'
  KycStatusStyle[(KycStatusStyle['success'] = 2)] = 'success'
  KycStatusStyle[(KycStatusStyle['danger'] = 99)] = 'danger'
})(KycStatusStyle || (KycStatusStyle = {}))

export {KycStatusStyle}

var OccupationScore
;(function (OccupationScore) {
  OccupationScore[(OccupationScore['reject'] = 0)] = 'Reject'
  OccupationScore[(OccupationScore['low'] = 1)] = 'Low'
  OccupationScore[(OccupationScore['high'] = 30)] = 'High'
})(OccupationScore || (OccupationScore = {}))

export {OccupationScore}

var OccupationScoreStyle
;(function (OccupationScoreStyle) {
  OccupationScoreStyle[(OccupationScoreStyle['dark'] = 0)] = 'danger'
  OccupationScoreStyle[(OccupationScoreStyle['warning'] = 1)] = 'success'
  OccupationScoreStyle[(OccupationScoreStyle['danger'] = 30)] = 'danger'
})(OccupationScoreStyle || (OccupationScoreStyle = {}))

export {OccupationScoreStyle}

export type OrderSideType = 'buy' | 'sell'

export enum OrderSideStyle {
  buy = 'success',
  sell = 'danger',
}

export enum AssessmentRiskStyle {
  low = 'success',
  medium = 'warning',
  high = 'danger',
  veryHigh = 'danger',
}

export enum UserRiskTypeStyle {
  low = 'success',
  medium = 'warning',
  high = 'danger',
}

export enum UserActiveStatusStyle {
  active = 'success',
  suspended = 'warning',
  inactive = 'dark',
  closed = 'danger',
}

export enum RiskTypeStatusStyle {
  low = 'success',
  betweenLowMedium = 'warning',
  betweenMediumHigh = 'warning',
  high = 'danger',
  veryHigh = 'danger',
}

export type Customer = {
  id?: ID
  kyc?: KycCustomer
  ndid?: Ndid
  profile?: ProfileCustomer
  transaction?: any
  dopa?: Dopa
  dipchip?: DipChip
  pep?: {
    status: boolean
  }
  bankrupt?: {
    status: boolean
  }
}

export type DipChip = {
  dipchip_check_date?: string
  dipchip_check_by?: string
  dipchip_status?: boolean
  remark?: string
  filename?: string
  dipchip_storage_path?: string
}
export type Ndid = {
  idpPublic?: IdpPublic
  ndid_ref_response?: NdidRefResponse
  term_accept?: TermAccept
}

export type TermAccept = {
  accept?: boolean
}

export type NdidRefResponse = {
  idpName?: string
}

export type IdpPublic = {
  [key: string]: IdpPublicData[]
}

export type IdpPublicData = {
  ASData?: {data: string}[]
  callback?: IdpPublicDataCallback
}

export type IdpPublicDataCallback = {
  updated_timestamp?: string
  reference_id?: string
}

export type Dopa = {
  check_date?: string
  code?: string
  desc?: string
  dopa_check_status?: string
  check_by?: string
  dopa_status_raw?: DopaStatusRaw
}

export type DopaStatusRaw = {
  code?: number
  desc?: string
}

export type KycCustomer = {
  personal_information?: PersonalInformationCustomer
  address_id_card?: AddressCustomer
  address_current?: AddressCustomer
  address_working?: AddressCustomer
  address_other?: AddressCustomer
  general_data?: GeneralData
  kyc_document?: KycDocument
  personal_fund?: PersonalFund
  term?: TermAndCondition
  fatca_usa?: FatcaUsa
  assessment_knowledge?: AssessmentKnowledge
  assessment_suiteable?: AssessmentSuiteable
  approve_kyc?: {
    approver?: {
      CS?: Approver
      CFO?: Approver
      CEO?: Approver
    }
  }
}

export type Approver = {
  approver_level?: string
  approver_date?: string
  approver_name?: string
  approver_email?: string
  approve_status?: 'approve' | 'rejected' | 'request_documents'
  remark?: string
  files?: string[]
  is_active?: boolean
}

export type AssessmentKnowledge = {
  created_at?: string
  score?: number
  user_choices?: UserChoices[]
}

export type AssessmentSuiteable = {
  created_at?: string
  score?: number
  user_choices?: UserChoices[]
  iwt_risk_id?: string
  risk_type?: string
  iwt_score?: number
}

export type UserChoices = {
  answer_id?: string[]
  point?: number
  question_id?: string
}

export type FatcaUsa = {
  accept?: boolean
}

export type TermAndCondition = {
  accept_aconnec?: boolean
  accept_risk?: boolean
  accept_collect_data?: boolean
  accept_to_use_marketing?: boolean
  accept_to_use_financial?: boolean
  accept_to_development?: boolean
}

export type PersonalFund = {
  occupation_group_id?: number
  occupation_group_name?: string
  occupation_group_score?: number
  occupation_id?: number
  occupation?: string
  occupation_other?: string
  occupation_score?: number
  occupation_level?: number

  another_occupation_group_id?: number
  another_occupation_group_name?: string
  another_occupation_group_score?: number
  another_occupation_id?: number
  another_occupation?: string
  another_occupation_other?: string
  another_occupation_score?: number
  another_occupation_level?: number

  another_job?: boolean
  another_job_salary_range?: string
  working_placeName?: string
  working_email_address?: string
  working_phone_number?: string
  politic_answers?: {
    answer_id?: Array<string>
    point?: number
    question_id?: string
  }
  occupation_status?: boolean
  occupation_remark?: string
}

export type AddressCustomer = {
  same_as?: string
  created_date?: string
  district_id?: number
  district?: string
  house_name?: string
  house_number?: string
  moo?: string
  post_code?: number
  province_id?: number
  province?: string
  road?: string
  soi?: string
  sub_district?: string
  sub_district_id?: number
  id_address_country?: string
}

export type KycDocument = {
  back_id_card?: DocumentType
  front_id_card?: DocumentType
  selfie?: DocumentType
}

export type GeneralData = {
  is_thai_nationality?: boolean
  user_risk_score?: number
  user_risk_type?: UserRiskType
  user_type?: string
  transaction_id_ref?: string
}

export type DocumentType = {
  created_at?: string
  path?: string
  updated_at?: string
}

export type UserRiskType = 'low' | 'medium' | 'high'

export type PersonalInformationCustomer = {
  created_at?: string
  date_of_birth?: string
  gender?: string
  id_card_expired_at?: string
  id_card_lifetime?: boolean
  legal_firstname_EN?: string
  legal_firstname_TH?: string
  legal_id?: string
  legal_laser_id?: string
  legal_lastname_EN?: string
  legal_lastname_TH?: string
  title_TH?: string
  title_EN?: string
  legal_midname_EN?: string
  title_id?: number
  document_reference?: string
  document_reference_date?: string
}

export type ProfileCustomer = {
  profile_name?: string
  profile_picture?: string
  customer_id?: string
  mobile?: string
  email?: string
  user_created_at?: string
  user_last_update?: string
  user_update_by?: string
  user_active_status?: UserActiveStatus
  customer_status?: string
  dealer_customer_status?: string
  kyc_status?: string
  kyc_level?: number
  id_firebase?: string
  bank_accounts?: BankAccounts[]
  user_status_updated_remark?: string
  user_status_updated_date?: string
  user_status_updated_by?: string
}

export type BankAccounts = {
  branch_code?: string
  bank_account_name?: string
  bank_account_number?: string
  bank_code?: string
  bank_name?: string
  bank_status?: string
  remark?: string
  book_bank_storage_path?: string
  verified_date?: string
  verified_by?: string
  proof_storage_path?: string
}

export type CustomersQueryResponse = Response<Array<Customer>>

export type CustomerQueryResponse = Response<Customer> | undefined

export type UserActiveStatus = 'active' | 'inactive' | 'suspended' | 'terminated'

export type Province = {
  created_at: string
  geography_id: number
  id: number
  name_en: string
  name_th: string
  updated_at: string
}

export type ProvinceQueryResponse = Response<Array<Province>> | undefined

export type District = {
  created_at: string
  id: number
  name_en: string
  name_th: string
  province_id: number
  updated_at: string
}

export type DistrictQueryResponse = Response<Array<District>> | undefined

export type SubDistrict = {
  amphure_id: number
  created_at: string
  id: number
  name_en: string
  name_th: string
  updated_at: string
  zip_code: number
}

export type SubDistrictQueryResponse = Response<Array<SubDistrict>> | undefined

export type Politic = {
  answers: Array<{
    answer: {
      en: string
      th: string
    }
    id: number
    point: number
  }>
  id: number
  multiple: boolean
  question: {
    en: string
    th: string
  }
  select_item: boolean
}

export type PoliticQueryResponse = Array<Politic>

export type OccupationGroup = {
  auto_reject: boolean
  group_name: string
  has_sub_occupation: boolean
  id: number
  score: number
}

export type OccupationGroupQueryResponse = Array<OccupationGroup>

export type OccupationList = {
  group_id: number
  id: number
  need_information: boolean
  occupation_name: string
  score: number
}

export type OccupationListQueryResponse = Array<OccupationList>

//api

export type Amlo = {
  ENTITY_ID?: string
  DATE_OF_BIRTH?: string
  LAST_UPDATE_DATE?: string
  INFO_SOURCE?: string
  GENDER?: string
  AMLO_ID_NUMBER?: {
    ID_VALUE?: string
    ID_TYPE?: string
  }[]
  AMLO_NAME?: {
    SINGLE_STRING_NAME?: string
    ORIGINAL_SCRIPT_NAME?: string
  }[]
}

export type AmloQueryResponse = Response<Array<Amlo>>

export type AmloCheckVerion = {
  upload?: {
    createdAt?: string
    create_by?: string
  }
  version?: {
    source?: string
    version_number?: string
    need_update?: boolean
  }[]
}

export type AmloCheckVerionQueryResponse = Response<AmloCheckVerion> | undefined

export type AmloForUser = {
  amlo_status: boolean
  check_status: boolean
  create_by: string | null
  createdAt: Date | string
}

export type AmloForUserQueryResponse = Response<Array<AmloForUser>>

export type AmloCheckBody = {
  uid?: string
  identificationId?: string
  createBy?: string
}

export type CompanyWatchlist = {
  id?: number
  firstName_th: string
  firstName_en: string
  middleName_th?: string
  middleName_en?: string
  surname_th: string
  surname_en: string
  fullName_th: string
  fullName_en: string
  idCardNo?: string
  passportId?: string
  country?: string
  birthDate?: Date | string
  homeCountry?: string
  remark?: string
  createdAt?: Date | string
}

export type CompanyWatchlistQueryResponse = Response<Array<CompanyWatchlist>>

export type CompanyWatchlistForUser = {
  firebaseUID: string
  check_status: boolean
  create_by?: string | null
  createdAt?: Date | string
  watchlist_status: boolean
}

export type CompanyWatchlistCheckBody = {
  uid?: string
  identificationId?: string
  createBy?: string
}

export type CompanyWatchlistForUserResponse = Response<Array<CompanyWatchlistForUser>>

export type PepForUser = {
  id?: number
  firebaseUID?: string
  result?: string
  peps_status?: boolean
  create_by?: string
  imageUrl?: string
  createdAt?: string
}

export type PepForUserQueryResponse = Response<Array<PepForUser>>

export type BankruptForUser = {
  id?: number
  firebaseUID?: string
  result?: string
  bankrupt_status?: boolean
  create_by?: string
  imageUrl?: string
  createdAt?: string
}

export type BankruptForUserQueryResponse = Response<Array<BankruptForUser>>

export type DopaCheckBody = {
  pid?: string
  firstName?: string
  lastName?: string
  birthDay?: string
  laser?: string
  uid?: string
  createBy?: string
}

export type DopaCheckQueryResponse = {
  dopa_status_raw: {
    code: number
    desc: string
    errorMessage: string
    isError: boolean
  }
  dopa_check_status: boolean
}

export type Blacklist = {
  id?: number
  firebaseUID?: string
  check_status?: boolean
  status?: boolean
  create_by?: string
  createdAt?: string
  type?: string
  info_source?: string
}

export type BlacklistAll = {
  company_watchlist?: {
    [key: string]: Array<Blacklist>
  }
  preps?: {
    [key: string]: Array<Blacklist>
  }
  bankrupt?: {
    [key: string]: Array<Blacklist>
  }
  amlo?: {
    [key: string]: Array<Blacklist>
  }
}

export type BlacklistAllQueryResponse = Response<Array<Blacklist>>

// Api Transactions

export type ApiResponse<T> = {
  totalRows?: number
  currentPage?: number
  totalPages?: number
  rows?: T
}

export interface OrderModel {
  bankaccount_no?: string
  cancel_reason?: string
  datetime_complete?: string
  datetime_create?: string
  datetime_payment?: string
  datetime_refcode?: string
  digitalasset_fee_set?: number
  digitalasset_price_actual?: number
  digitalasset_price_set?: number
  digitalasset_type?: string
  id_order?: number
  id_user?: string
  listed_pair?: string
  order_side?: OrderSideType
  order_value_crypto_set?: number
  order_value_fiat_set?: number
  refcode?: string
  status?: string
  topay_expire_time?: number
  wallet_address?: string
}

export type OrderModelQueryResponse = ApiResponse<Array<OrderModel>>

export interface TrxDigitalAssetModel {
  approve_datetime?: string
  approver?: string
  audit_datetime?: string
  auditor?: string
  cancel_reason?: string
  datetime_complete?: string
  datetime_create?: string
  datetime_payment?: string
  digitalasset_price_actual?: number
  digitalasset_price_set?: number
  flag_message?: string
  id_order?: number
  id_trxdigitalasset?: number
  is_approved?: boolean
  is_audited?: boolean
  is_needapprove?: boolean
  is_needaudit?: boolean
  order_side?: OrderSideType
  payer?: string
  payment_autosettle?: boolean
  payment_callbacklog?: string
  payment_channel?: string
  payment_currency?: string
  payment_proof?: string
  payment_value_actual?: number
  payment_value_fee?: number
  payment_value_set?: number
  payment_wallet_des_actual?: string
  payment_wallet_src_actual?: string
  status?: string
  refcode?: string
}

export type TrxDigitalAssetModelQueryResponse = ApiResponse<Array<TrxDigitalAssetModel>>

export interface TrxFiatModel {
  approve_datetime?: string
  approver?: string
  audit_datetime?: string
  auditor?: string
  cancel_reason?: string
  datetime_complete?: string
  datetime_create?: string
  datetime_payment?: string
  digitalasset_price_actual?: number
  digitalasset_price_set?: number
  flag_message?: string
  id_order?: number
  id_trxfiat?: number
  is_approved?: boolean
  is_audited?: boolean
  is_needapprove?: boolean
  is_needaudit?: boolean
  order_side?: OrderSideType
  payer?: string
  payment_account_des_actual?: string
  payment_account_src_actual?: string
  payment_autosettle?: boolean
  payment_callbacklog?: string
  payment_channel?: string
  payment_currency?: string
  payment_proof?: string
  payment_value_actual?: number
  payment_value_fee?: number
  payment_value_set?: number
  status?: string
  refcode?: string
}

export type TrxFiatModelQueryResponse = ApiResponse<Array<TrxFiatModel>>

export type TrxFiatCreateRequest = {
  id_order: number
  is_needapprove?: boolean
  is_needaudit?: boolean
  payment_autosettle?: boolean
  payment_channel: string
  payment_currency?: string
  payment_value_actual?: number
  payment_value_fee?: number
  payment_value_set: number
  payment_account_src_actual?: string
  payment_account_des_actual?: string
  refcode?: string
}

export type DigitalAssetCreateRequest = {
  id_order: number
  is_needapprove?: boolean
  is_needaudit?: boolean
  payment_autosettle?: boolean
  payment_channel: string
  payment_currency?: string
  payment_value_actual?: number
  payment_value_fee?: number
  payment_value_set: number
  payment_wallet_des_actual?: string
  payment_wallet_src_actual?: string
  refcode?: string
}

export type ActiveStatusEdit = {
  user_active_status?: UserActiveStatus
  user_status_updated_remark?: string
  user_status_updated_date?: string
  user_status_updated_by?: string
}

export type OrderFull = {
  order?: OrderModel
  trxfiat?: TrxFiatModel
  trxdigitalasset?: TrxDigitalAssetModel
}

export type OrderFullQueryResponse = Response<OrderFull>

export type UserDataSensitive = {
  pin?: string
  spend_limit_buy?: number
  spend_limit_sell?: number
  spend_limit_ref?: string
  spend_limit_updated_by?: string
  spend_limit_updated_date?: string
}

export type UserDataSensitiveResponse = Response<UserDataSensitive> | undefined

export interface PureFiatModel {
  audit_datetime?: string
  auditor?: string
  cancel_reason?: string
  datetime_complete?: string
  datetime_create?: string
  datetime_payment?: string
  flag_message?: string
  id_order?: number
  id_purefiat?: number
  is_audited?: boolean
  is_needaudit?: boolean
  payer?: string
  payment_account_des_actual?: string
  payment_account_src_actual?: string
  payment_autosettle?: boolean
  payment_callbacklog?: string
  payment_channel?: string
  payment_currency?: string
  payment_proof?: string
  payment_type?: string
  payment_value?: number
  payment_value_actual?: number
  payment_value_fee?: number
  refcode?: string
  status?: string
  id_user?: string
}

export type PureFiatModelQueryResponse = ApiResponse<Array<PureFiatModel>>

export type BitgoSendRequest = {
  amount: string
  coin: string
  destination_address: string
  otp: string
}
