import {lazy, FC, Suspense, ReactNode} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {find} from 'lodash'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {useAuth} from '../modules/auth'
import {useUserPermissionAdmin} from '../../_metronic/core/_requests'
import {Error403} from '../modules/errors/components/Error403'

interface IProtectedRoute {
  children: ReactNode
  roles?: string[]
}

const ProtectedRoute: FC<IProtectedRoute> = ({children, roles = []}) => {
  const {currentUser} = useAuth()
  const {data} = useUserPermissionAdmin(currentUser?.email)
  if (data === undefined) return <></>

  const isAuthorized = find(roles, function (o) {
    return o === data
  })

  if (isAuthorized === undefined) {
    return <Navigate to='/not-authorized' />
  }

  return <>{children}</>
}

const PrivateRoutes = () => {
  const CustomersManagementPage = lazy(() => import('../pages/customers-management/CustomersPage'))
  const ReceivingWrapper = lazy(() => import('../pages/receiving/ReceivingPage'))
  const TransferringWrapper = lazy(() => import('../pages/transferring/TransferringPage'))
  const CustomerPage = lazy(() => import('../pages/customer-profile/CustomerProfilePage'))
  const TransactionsPage = lazy(() => import('../pages/transactions/TransactionsPage'))
  const BlacklistingManagementPage = lazy(
    () => import('../pages/blacklisting-management/BlacklistingManagementPage')
  )
  const PureFiatWrapper = lazy(() => import('../pages/pure-fiat/PureFiatWrapper'))
  const DigitalAssetPage = lazy(() => import('../pages/digital-asset/DigitalAssetPage'))
  const OrderDetailsPageWrapper = lazy(
    () => import('../pages/order-details/OrderDetailsPageWrapper')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route
          path='dashboard'
          loader={async () => console.log('DashboardWrapper')}
          element={
            <ProtectedRoute roles={['level0', 'level1', 'level2', 'level3', 'level4']}>
              <DashboardWrapper />
            </ProtectedRoute>
          }
        />
        <Route
          path='receiving/*'
          element={
            <ProtectedRoute roles={['level0', 'level1', 'level2', 'level3', 'level4']}>
              <SuspensedView>
                <ReceivingWrapper />
              </SuspensedView>
            </ProtectedRoute>
          }
        />
        <Route
          path='transferring/*'
          element={
            <ProtectedRoute roles={['level0', 'level1', 'level2', 'level3', 'level4']}>
              <SuspensedView>
                <TransferringWrapper />
              </SuspensedView>
            </ProtectedRoute>
          }
        />

        <Route
          path='customers-management/*'
          element={
            <ProtectedRoute roles={['level0', 'level1', 'level2', 'level3', 'level4']}>
              <SuspensedView>
                <CustomersManagementPage />
              </SuspensedView>
            </ProtectedRoute>
          }
        />

        <Route
          path='customer/*'
          element={
            <ProtectedRoute roles={['level0', 'level1', 'level2', 'level3', 'level4']}>
              <SuspensedView>
                <CustomerPage />
              </SuspensedView>
            </ProtectedRoute>
          }
        />
        <Route
          path='transactions/*'
          element={
            <ProtectedRoute roles={['level0', 'level1', 'level2', 'level3', 'level4']}>
              <SuspensedView>
                <TransactionsPage />
              </SuspensedView>
            </ProtectedRoute>
          }
        />

        <Route
          path='order/:id'
          element={
            <ProtectedRoute roles={['level0', 'level1', 'level2', 'level3', 'level4']}>
              <SuspensedView>
                <OrderDetailsPageWrapper />
              </SuspensedView>
            </ProtectedRoute>
          }
        />

        <Route
          path='pure-fiat'
          element={
            <ProtectedRoute roles={['level0', 'level1', 'level2', 'level3', 'level4']}>
              <SuspensedView>
                <PureFiatWrapper />
              </SuspensedView>
            </ProtectedRoute>
          }
        />

        <Route
          path='blacklisting-management/*'
          element={
            <ProtectedRoute roles={['level0', 'level1', 'level2', 'level3', 'level4']}>
              <SuspensedView>
                <BlacklistingManagementPage />
              </SuspensedView>
            </ProtectedRoute>
          }
        />

        <Route
          path='digital-asset/*'
          element={
            <ProtectedRoute roles={['level3', 'level4']}>
              <SuspensedView>
                <DigitalAssetPage />
              </SuspensedView>
            </ProtectedRoute>
          }
        />
        <Route path='not-authorized' element={<Error403 />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
