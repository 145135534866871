import moment from 'moment'
import {storageFirebase} from '../../firebase'
import {
  ref,
  getDownloadURL,
  list,
  ListResult,
  StorageReference,
  uploadBytes,
  UploadResult,
} from 'firebase/storage'

const getStorageItems = (paths: string): Promise<ListResult> => {
  const starsRef = ref(storageFirebase, paths)
  return list(starsRef)
}

const getStorageByPath = (paths: string[]): Promise<string[]> => {
  let urlPromises = paths
    .filter((v) => v !== '')
    .map((imageRef) => {
      if (imageRef === null) return ''
      const starsRef = ref(storageFirebase, imageRef)
      return getDownloadURL(starsRef)
    })

  return Promise.all(urlPromises)
}

const uploadFileStorage = async (path: string, file: File): Promise<StorageReference> => {
  const storageRef = ref(storageFirebase, path)
  var uploadTask
  await uploadBytes(storageRef, file)
    .then((snapshot) => {
      uploadTask = snapshot.ref
      return snapshot.ref
    })
    .catch((error) => {
      switch (error.code) {
        case 'storage/unauthorized':
          throw new Error("User doesn't have permission to access the object")
        case 'storage/canceled':
          throw new Error('User canceled the upload')
        case 'storage/unknown':
          throw new Error('Unknown error occurred, inspect error.serverResponse')
      }
    })

  return uploadTask
}

const uploadFilesStorage = async (
  path: string,
  name: string,
  files: File[]
): Promise<UploadResult[]> => {
  let urlPromises = files.map((file) => {
    const starsRef = ref(
      storageFirebase,
      `${path}/${name}_${moment().valueOf()}.${file.name.split('.').pop()}`
    )
    return uploadBytes(starsRef, file)
  })
  return Promise.all(urlPromises)
}

export {getStorageItems, getStorageByPath, uploadFileStorage, uploadFilesStorage}
