/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.KYC'})}
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/waiting-review'
        icon='profile-circle'
        title={intl.formatMessage({id: 'MENU.WAITING_REVIEW'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/waiting-approve'
        icon='profile-circle'
        title={intl.formatMessage({id: 'MENU.WAITING_APPROVE'})}
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Receiving</span>
        </div>
      </div>
      <SidebarMenuItem to='/receiving/fiat' icon='element-11' title='Fiat' fontIcon='bi-layers' />
      <SidebarMenuItem
        to='/receiving/crypto'
        icon='element-11'
        title='Crypto'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Transferring</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/transferring/crypto'
        icon='element-11'
        title='Crypto'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/transferring/fiat'
        icon='element-11'
        title='Fiat'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Operation</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/customers-management/customers'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.CUSTOMERS'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/blacklisting-management'
        icon='element-11'
        title={'Blacklisting'}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/transactions'
        icon='element-11'
        title='Transactions'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem to='/pure-fiat' icon='element-11' title='Pure Fiat' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>System</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/digital-asset'
        icon='element-11'
        title='Digital Asset'
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
