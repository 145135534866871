import {database} from '../../firebase'
import {ref, query, get, update} from 'firebase/database'
import {filter} from 'lodash'
import {
  CustomerQueryResponse,
  DistrictQueryResponse,
  OccupationGroupQueryResponse,
  OccupationListQueryResponse,
  PoliticQueryResponse,
  ProvinceQueryResponse,
  SubDistrictQueryResponse,
} from './_models'
import {ID} from '../helpers'

const getCustomerById = async (id: ID): Promise<CustomerQueryResponse> => {
  const recentPostsRef = query(ref(database, `user/${id}`))
  const customer = await get(recentPostsRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val()
      } else {
        return undefined
      }
    })
    .catch(() => {
      return undefined
    })
  const data: CustomerQueryResponse = {
    data: customer,
  }

  return data
}

const updateCustomerById = async (id: ID, data: any, path = ''): Promise<void> => {
  const updates = {}
  updates['user/' + id + path] = data

  return update(ref(database), updates)
}

const getProvince = async (): Promise<ProvinceQueryResponse> => {
  const recentPostsRef = query(ref(database, `system/data/thailand_address/province`))
  const res = await get(recentPostsRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val()
      } else {
        return undefined
      }
    })
    .catch(() => {
      return undefined
    })
  const data: ProvinceQueryResponse = {
    data: res,
  }

  return data
}

const getDistrict = async (id?: number): Promise<DistrictQueryResponse> => {
  const recentPostsRef = query(ref(database, `system/data/thailand_address/district`))
  const res = await get(recentPostsRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val()
      } else {
        return undefined
      }
    })
    .catch(() => {
      return undefined
    })
  const data: DistrictQueryResponse = {
    data: filter(res, {province_id: id}),
  }

  return data
}

const getSubDistrict = async (id?: number): Promise<SubDistrictQueryResponse> => {
  const recentPostsRef = query(ref(database, `system/data/thailand_address/sub_district`))
  const res = await get(recentPostsRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val()
      } else {
        return undefined
      }
    })
    .catch(() => {
      return undefined
    })
  const data: SubDistrictQueryResponse = {
    data: filter(res, {amphure_id: id}),
  }

  return data
}

const getPolitic = async (): Promise<PoliticQueryResponse> => {
  const recentPostsRef = query(ref(database, `system/data/polictic`))
  const res = await get(recentPostsRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val()
      } else {
        return undefined
      }
    })
    .catch(() => {
      return undefined
    })

  return res
}

const getOccupationGroup = async (): Promise<OccupationGroupQueryResponse> => {
  const recentPostsRef = query(ref(database, `system/data/occupation/group`))
  const res = await get(recentPostsRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val()
      } else {
        return []
      }
    })
    .catch(() => {
      return []
    })

  return res
}

const getOccupationList = async (id?: number): Promise<OccupationListQueryResponse> => {
  const recentPostsRef = query(ref(database, `system/data/occupation/list`))
  const res = await get(recentPostsRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val()
      } else {
        return []
      }
    })
    .catch(() => {
      return []
    })
  return filter(res, {group_id: id})
}

export {
  getCustomerById,
  updateCustomerById,
  getProvince,
  getDistrict,
  getSubDistrict,
  getPolitic,
  getOccupationGroup,
  getOccupationList,
}
