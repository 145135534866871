// @ts-nocheck
import {FC} from 'react'
import {ColumnInstance} from 'react-table'

type Props = {
  column: ColumnInstance
}

const KTCustomHeaderColumn: FC<Props> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th
        className='text-gray-800 fw-bold fs-4'
        style={{textTransform: 'capitalize'}}
        {...column.getHeaderProps()}
      >
        {column.render('Header')}
      </th>
    ) : (
      column.render('Header')
    )}
  </>
)

export default KTCustomHeaderColumn
