import {useLayout} from '../layout/core'
import {ThemeModeComponent} from '../assets/ts/layout'
import Swal, {SweetAlertIcon, SweetAlertInput} from 'sweetalert2'

interface IParam {
  title: string
  text?: string
  icon?: SweetAlertIcon
  onConfirmed?: () => void
  confirmButtonText?: string
  cancelButtonText?: string
}

interface IInputParam {
  title: string
  text?: string
  icon?: SweetAlertIcon
  onConfirmed?: (string) => void
  confirmButtonText?: string
  cancelButtonText?: string
  inputPlaceholder?: string
  input?: SweetAlertInput
}

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary min-w-150px',
    cancelButton: 'btn btn-secondary min-w-150px',
  },
  buttonsStyling: false,
})

export const dialogConfirm = ({
  title,
  text,
  icon = 'warning',
  onConfirmed,
  confirmButtonText = 'ยืนยัน',
  cancelButtonText = 'ยกเลิก',
}: IParam) =>
  swalWithBootstrapButtons
    .fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (onConfirmed) onConfirmed()
      }
    })

export const dialogInputConfirm = ({
  title,
  text,
  icon = 'warning',
  onConfirmed,
  confirmButtonText = 'ยืนยัน',
  cancelButtonText = 'ยกเลิก',
  inputPlaceholder = '',
  input = 'text',
}: IInputParam) =>
  swalWithBootstrapButtons
    .fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
      input,
      inputPlaceholder,
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (onConfirmed) onConfirmed(result.value)
      }
    })
