// @ts-nocheck
import {ColumnInstance, Row, TableInstance} from 'react-table'
import TableLoading from './TableLoading'
import KTCustomRow from './CustomRow'
import KTCustomHeaderColumn from './HeaderColumn'
import {PaginationState} from '../../crud-helper/models'
import KTPagination from './KTPagination'
import {useAuth} from '../../../../app/modules/auth'
import {useUserPermissionAdmin} from '../../../core/_requests'

type Props<D extends object = {}> = {
  tableInstance: TableInstance<D>
  isLoading?: boolean
  pagination?: PaginationState
  updateState?: any
}

function KTTable<D extends object = {}>({
  tableInstance,
  isLoading = false,
  pagination,
  updateState,
}: Props<D>) {
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = tableInstance
  const {currentUser} = useAuth()
  const {data: adminLevel} = useUserPermissionAdmin(currentUser?.email)

  return (
    <div>
      <div className='table-responsive'>
        <table
          className='table align-middle table-row-bordered table-rounded  gy-5 gs-5 border fs-6 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<D>) => (
                <KTCustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-500 fw-bold fs-6' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<D>, i) => {
                prepareRow(row)
                return (
                  <KTCustomRow
                    row={row}
                    key={`row-${i}-${row.id}`}
                    adminLevel={adminLevel}
                    userEmail={currentUser?.email}
                  />
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {pagination && (
        <KTPagination pagination={pagination} updateState={updateState} isLoading={isLoading} />
      )}
      {isLoading && <TableLoading />}
    </div>
  )
}

export {KTTable}
