import {fireStoreFB} from '../../firebase'
import {getDocs, collection, query, where} from 'firebase/firestore/lite'
import {get} from 'lodash'

const getUserPermissionAdmin = async (email?: string | null): Promise<String | undefined> => {
  if (email === null || email === undefined) return undefined

  const queryRef = query(
    collection(fireStoreFB, 'user_permission_admin'),
    where('user_list', 'array-contains', email)
  )
  const querySnapshot = await getDocs(queryRef)

  return get(querySnapshot, 'docs[0].id')
}

export {getUserPermissionAdmin}
