import * as CryptoJS from 'crypto-js'

export const cryptoEncrypt = (text?: string): string | undefined => {
  if (text === undefined) return undefined

  const aesKey = 'NDIICTxfHlUQd8EkrSboxUktb2bAIC4E'
  var key = CryptoJS.enc.Utf8.parse(aesKey)
  var iv = CryptoJS.lib.WordArray.create([0x00000000], 16)
  var encrypt = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CFB,
  })
  return encrypt.toString()
}

export const cryptoDecrypt = (base64String?: string): string | undefined => {
  if (base64String === undefined) return undefined

  const aesKey = 'NDIICTxfHlUQd8EkrSboxUktb2bAIC4E'
  var key = CryptoJS.enc.Utf8.parse(aesKey)
  var iv = CryptoJS.lib.WordArray.create([0x00000000], 16)
  var decrypt = CryptoJS.AES.decrypt(base64String, key, {
    iv: iv,
    mode: CryptoJS.mode.CFB,
  })

  return decrypt.toString(CryptoJS.enc.Utf8)
}
