function getFileName(fullPath?: string): string {
  if (!fullPath) return ''
  return fullPath.replace(/^.*[\\\/]/, '')
}

function parseStringJson(data?: string): object {
  if (data === undefined || data === null) {
    return {}
  }

  try {
    var obj = JSON.parse(data)
    if (typeof obj == 'object') {
      return obj
    } else {
      return {}
    }
  } catch {
    return {}
  }
}

export {getFileName, parseStringJson}
