import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const Error403: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='w-lg-650px py-5'>
            <div className='py-15 py-lg-20'>
              <>
                {/* begin::Title */}
                <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!403</h1>
                {/* end::Title */}

                {/* begin::Text */}
                <div className='fw-semibold fs-6 text-gray-500 mb-7'>
                  Sorry, you are not authorized to access this page.
                </div>
                {/* end::Text */}

                {/* begin::Illustration */}
                {/* <div className='mb-3'>
                  <img
                    src={toAbsoluteUrl('/media/auth/404-error.png')}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                  />
                  <img
                    src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
                    className='mw-100 mh-300px theme-dark-show'
                    alt=''
                  />
                </div> */}
                {/* end::Illustration */}

                {/* begin::Link */}
                <div className='mb-0'>
                  <Link to='/dashboard' className='btn btn-sm btn-primary'>
                    Return Home
                  </Link>
                </div>
                {/* end::Link */}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Error403}
