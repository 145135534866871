import {FC, ReactElement, useCallback, useEffect, useRef, useState} from 'react'
import {PhotoSlider} from 'react-photo-view'
import {saveAs} from 'file-saver'
type Props = {
  action: Promise<string[]>
  children: ReactElement

  disabled?: boolean
}

const KTFileView: FC<Props & {fileType?: string}> = ({fileType, ...props}) => {
  if (fileType === 'pdf') return <KTPdfView {...props} />
  return <KTImageView {...props} />
}

const KTPdfView: FC<Props> = ({children, action, disabled = false}) => {
  const loadDataOnlyOnce = () => {
    try {
      action.then((urls) => {
        urls.map((url) => saveAs(url))
      })
    } catch (error) {}
  }

  return (
    <div className='overlay-wrapper'>
      <div onClick={() => (disabled ? null : loadDataOnlyOnce())}>{children}</div>
    </div>
  )
}
const KTImageView: FC<Props> = ({children, action, disabled = false}) => {
  const [images, setImages] = useState<string[]>([])
  const [visible, setVisible] = useState(false)
  const [index, setIndex] = useState(0)
  const timelineLoaded = useRef<boolean>(false)
  const loadDataOnlyOnce = useCallback(() => {
    try {
      if (!visible) return
      if (timelineLoaded.current) return

      action.then((urls) => {
        setImages(urls)
        timelineLoaded.current = true
      })
    } catch (error) {}
  }, [visible])

  useEffect(() => {
    loadDataOnlyOnce()
  }, [loadDataOnlyOnce])

  return (
    <div className='overlay-wrapper'>
      <div onClick={() => (disabled ? null : setVisible(true))}>{children}</div>
      <PhotoSlider
        images={images.map((image, index) => ({src: image, key: index}))}
        visible={visible}
        onClose={() => setVisible(false)}
        index={index}
        onIndexChange={setIndex}
      />
    </div>
  )
}

export {KTFileView}
