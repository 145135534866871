import {FC} from 'react'
import Select from 'react-select'
import {find} from 'lodash'
import clsx from 'clsx'

export interface InputSelectOption {
  value: string | number | boolean
  label: string | number
}

type Props = {
  className?: string
  options?: InputSelectOption[]
  onChange?: (InputSelectOption?) => void
  value?: string | number | boolean
  disabled?: boolean
}

const InputSelect: FC<Props> = ({className, options, onChange, value, disabled}) => {
  return (
    <div className={clsx('p-0 py-0', className)}>
      <Select<InputSelectOption>
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 6.175,
          colors: {
            ...theme.colors,
            neutral5: '#f9f9f9',
          },
        })}
        styles={{
          control: (base, state) => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            '&:hover': {
              border: 0,
            },
            minHeight: '41px',
            height: '41px',
          }),
          indicatorSeparator: (state) => ({
            display: 'none',
          }),
        }}
        isDisabled={disabled}
        options={options}
        onChange={onChange}
        value={
          find(options, function (o) {
            return o.label === value || o.value === value
          }) || ''
        }
      />
    </div>
  )
}

export {InputSelect}
