import {FC, useCallback, useState} from 'react'
import {range} from 'lodash'
import DatePicker from 'react-datepicker'

import moment from 'moment'
import clsx from 'clsx'
import {KTIcon} from '../KTIcon'

type Props = {
  className?: string
  onChange?: (Date) => void
  value?: Date
  dateFnsFormat?: string
  disabled?: boolean
}

const styleComponent = `
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input[type="text"]{
  min-height: 41px;
  height: 41px;
  width: 100%;
  box-shadow: none;
}
.react-datepicker-wrapper input[type="text"]:focus {
  outline: 0px;
  outline-offset: 0px;
}
`

const InputDate: FC<Props> = ({
  className,
  onChange,
  value,
  dateFnsFormat = 'dd-MM-yyyy',
  disabled,
}) => {
  const years = range(1990, 2100, 1)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const MyContainer = ({className, children}) => {
    return (
      <div style={{background: '#fff'}}>
        <div className={className}>
          <div style={{position: 'relative'}}>{children}</div>
        </div>
      </div>
    )
  }

  return (
    <div className={clsx('p-0 py-0', className)}>
      <style>{styleComponent}</style>
      <DatePicker
        disabled={disabled}
        sx={{width: '100%'}}
        className='border-0 rounded px-4 '
        dateFormat={dateFnsFormat}
        InputProps={{
          disableUnderline: true,
        }}
        placeholderText={dateFnsFormat}
        calendarContainer={MyContainer}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {'<'}
            </button>
            <select
              value={moment(date, 'DD-MM-YYYY').year()}
              onChange={({target: {value}}) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[moment(date, 'DD-MM-YYYY').month()]}
              onChange={({target: {value}}) => changeMonth(months.indexOf(value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {'>'}
            </button>
          </div>
        )}
        selected={value}
        onChange={onChange}
      />
    </div>
  )
}

export {InputDate}
