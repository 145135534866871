/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {signInWithEmailAndPassword} from 'firebase/auth'
import Swal from 'sweetalert2'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {authFirebase} from '../../../../firebase'
import {signInWithPopup, GoogleAuthProvider} from 'firebase/auth'
import {getUserPermissionAdmin} from '../../../../_metronic/core'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const onSignInWithGoogle = async () => {
    try {
      setLoading(true)

      const provider = new GoogleAuthProvider()
      const data = await signInWithPopup(authFirebase, provider)
      const api_token = await data.user.getIdToken()
      saveAuth({api_token})

      const user = {
        id: data.user.uid,
        email: data.user.email ?? '',
        first_name: 'Admin',
        last_name: 'Admin',
      }
      setCurrentUser(user)
    } catch (error: any) {
      saveAuth(undefined)
      setLoading(false)
      const Toast = Swal.mixin({
        showConfirmButton: false,
        timer: 3000,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      })
      Toast.fire({
        icon: 'error',
        title: 'The login details are incorrect',
      })
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const data = await signInWithEmailAndPassword(authFirebase, values.email, values.password)
        const api_token = await data.user.getIdToken()
        console.log({data})
        const res = await getUserPermissionAdmin(data.user.email)
        if (res === undefined) {
        }
        saveAuth({api_token})
        const user = {
          id: data.user.uid,
          email: data.user.email ?? '',
          first_name: 'Admin',
          last_name: 'Admin',
        }
        setCurrentUser(user)
      } catch (error) {
        console.log({error})
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
        const Toast = Swal.mixin({
          showConfirmButton: false,
          position: 'center',
        })
        Toast.fire({
          icon: 'error',
          title: 'The login details are incorrect',
        })
      }
    },
  })

  return (
    <form
      className='form w-500px'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='card-body d-flex p-12 flex-column flex-row flex-column w-auto'>
        {/* <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
      </div>

      <div className='row g-3 mb-9'>
        <div className='col-md-12'>
          <div className='card card-custom card-border overlay overlay-block'>
            <a
              onClick={onSignInWithGoogle}
              className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                className='h-15px me-3'
              />
              Sign in with Google
            </a>
            {loading && (
              <div className='overlay-layer rounded bg-dark bg-opacity-5'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div> */}
        {/* end::Login options */}

        {/* begin::Separator */}
        {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}
        {/* end::Separator */}

        {/* <div className='mb-10 bg-light-info p-8 rounded'>
        <div className='text-info'>
          Use account <strong>admin@gmail.com</strong> and password <strong>admin123</strong> to
          continue.
        </div>
      </div> */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </div>
    </form>
  )
}
