const QUERIES = {
  USER_PERMISSION_ADMIN: 'user_permission_admin',
  USERS_LIST: 'users-list',
  CUSTOMER_BY_ID: 'customer-by-id',
  PROVINCE_LIST: 'province-id',
  DISTRICT_LIST: 'district-id',
  SUB_DISTRICT_LIST: 'sub_district-id',
  BANKRUPT_LIST: 'bankrupt',
  PEPS_LIST: 'peps',
  POLITIC_LIST: 'politic',
  OCCUPATION_GROUP: 'occupation_group',
  OCCUPATION_LIST: 'occupation_list',
  ANOTHER_OCCUPATION_GROUP: 'another_occupation_group',
  ANOTHER_OCCUPATION_LIST: 'another_occupation_list',
  ORDER_LIST: 'order_list',
  DIGITAL_ASSET_LIST: 'digital_asset_list',
  PURE_FIAT_LIST: 'pure_fiat_list',
  FIAT_LIST: 'fiat_list',
  ORDER_FULL_BY_ID: 'order-full-by-id',
  DAILY_LIMIT: 'daily-limit',
}

export {QUERIES}
