const config = {
  apiKey: 'AIzaSyA41TitvBmeOL-VUezFqBFmLZAwga28XCE',
  authDomain: 'gasta-app.firebaseapp.com',
  databaseURL: 'https://gasta-app-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'gasta-app',
  storageBucket: 'gasta-app.appspot.com',
  messagingSenderId: '809989295239',
  appId: '1:809989295239:web:d6c3338cd91966f5edfc22',
  measurementId: 'G-Z3QBQ5VR06',
}

export default config
