import clsx from 'clsx'
import {PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'

type Props<D extends object = {}> = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<D>>
}

function TableCustomHeader<D extends object = {}>({className, title, tableProps}: Props<D>) {
  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(['text-gray-800 fw-bold fs-4', className])}
      style={{textTransform: 'capitalize'}}
    >
      {title}
    </th>
  )
}

export {TableCustomHeader}
