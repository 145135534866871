import {KTIcon} from './KTIcon'
import {CSVLink} from 'react-csv'

const ExcelExportButton = ({csvHeaders, csvData, fileName}) => {
  return (
    <CSVLink headers={csvHeaders} data={csvData} filename={fileName}>
      <button type='button' className='d-flex align-items-center  btn btn-primary fw-bold'>
        <KTIcon iconName='exit-right' iconType='outline' className='fs-5' />
        <span className='indicator-label'>Export Excel</span>
      </button>
    </CSVLink>
  )
}
export {ExcelExportButton}
