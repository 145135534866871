import {useRef} from 'react'
import {KTFileView} from '../KTFileView'
import {toAbsoluteUrl} from '../../AssetHelpers'
import {KTIcon} from '../KTIcon'

type Props = {
  label?: string
  onChange?: (file?: File) => void
  value?: File
  disabled?: boolean
}
const InputAddImage: React.FC<Props> = ({onChange, value, label, disabled = false}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleFileInput = (e) => {
    const file = e.target.files[0]
    onChange?.(file)
  }

  return (
    <div className='d-flex flex-row flex-column-fluid justify-content-between align-items-center mt-3'>
      {value && (
        <div className='d-flex flex-row-fluid justify-content-between'>
          <div className='d-flex align-items-center'>
            <img
              alt=''
              className='w-30px h-30px me-3'
              src={toAbsoluteUrl(`/media/svg/files/${value.name.split('.').pop()}.svg`)}
            />
            <KTFileView
              action={Promise.all([URL.createObjectURL(value!)])}
              fileType={value.name.split('.').pop()}
              disabled={disabled}
            >
              <span className='text-primary text-hover-primary cursor-pointer fs-5 fw-semibold ms-6'>
                {value?.name}
              </span>
            </KTFileView>
          </div>
          <div className='d-flex align-items-center'>
            <button
              type='button'
              className='btn btn-bg-white btn-color-danger me-3'
              onClick={() => onChange?.(undefined)}
              disabled={disabled}
            >
              <KTIcon iconName='trash' iconType='outline' className='fs-5' />
              <span>ลบ</span>
            </button>
          </div>
        </div>
      )}

      {!value && (
        <div className='d-flex'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => inputRef.current?.click()}
            disabled={disabled}
          >
            <KTIcon iconName='picture' iconType='outline' className='fs-5' />
            <span>{label}</span>
          </button>
        </div>
      )}
      <input
        type='file'
        ref={inputRef}
        onChange={handleFileInput}
        style={{display: 'none'}}
        accept='image/png, image/jpg, image/jpeg ,.pdf'
      />
    </div>
  )
}

export {InputAddImage}
