import moment from 'moment'

function dateFormat(
  inp?: moment.MomentInput,
  options?: {format?: string; defaultValue?: string}
): string {
  if (inp === null || inp === undefined || inp === '') return options?.defaultValue ?? ''
  return moment(inp).format(options?.format ?? 'DD-MM-YYYY')
}

function dateFormatToDate(inp?: moment.MomentInput, options?: {format?: string}): Date | undefined {
  if (inp === null || inp === undefined || inp === '') return undefined

  if (moment(inp, 'DD-MM-YYYY').isValid() !== true) return undefined

  return moment(moment(inp, options?.format ?? 'DD-MM-YYYY')).toDate()
}

export {dateFormat, dateFormatToDate}
