import {initializeApp} from 'firebase/app'
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check'
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import {getDatabase} from 'firebase/database'
import {getStorage} from 'firebase/storage'
// import {getFirestore as getFirestoreDefault} from 'firebase/firestore'
import {getFirestore} from 'firebase/firestore/lite'
import config from './config'

const app = initializeApp(config)

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lf6XEQnAAAAAIYlOa1KQKJcFHFunKvK4-r2zQK2'),
  isTokenAutoRefreshEnabled: true,
})

export const authFirebase = getAuth(app)

onAuthStateChanged(authFirebase, (user) => {})
export const database = getDatabase(app)
export const storageFirebase = getStorage(app)

// export const fireStoreDefaultFB = getFirestoreDefault(app)
export const fireStoreFB1 = getFirestore(app)
export const fireStoreFB = getFirestore(app, 'core-system-1109x')

export default app
