import {useQuery} from 'react-query'
import axios, {AxiosResponse} from 'axios'

import {ID, QUERIES, Response} from '../helpers'
import {getOccupationGroup, getOccupationList} from './database_fb_requests'
import {getUserPermissionAdmin} from './firestore_fb_requests'
import {BitgoSendRequest, DigitalAssetCreateRequest, OrderFull, SendMessage} from './_models'

const API_URL = process.env.REACT_APP_API_URL_2
const SMS_API_URL = process.env.REACT_APP_API_URL_3
const POST_FIAT_CREATE_URL = `${API_URL}/trxfiat`
const POST_FIAT_AUDIT_URL = `${API_URL}/trxfiat/audit`
const POST_FIAT_CANCEL_URL = `${API_URL}/trxfiat/cancel`
const POST_FIAT_TRANSFERRING_URL = `${API_URL}/trxfiat/transferring`
const POST_FIAT_APPROVE_URL = `${API_URL}/trxfiat/approve`
const POST_FIAT_TRANSFERRING_CANCEL_URL = `${API_URL}/trxfiat/transfer_cancel`
const POST_FIAT_TRANSFERRING_FINISH_URL = `${API_URL}/trxfiat/transfer_finish`
const POST_FIAT_FINISH_URL = `${API_URL}/trxfiat/finish`
const POST_ORDER_DIGITAL_ASSET_PRICE_URL = `${API_URL}/order/digitalasset_price`
const POST_ORDER_PENDING_URL = `${API_URL}/order/pending`
const POST_ORDER_FINISH_URL = `${API_URL}/order/finish`
const POST_ORDER_UPDATE_TORECEIVE_URL = `${API_URL}/order/toreceive`
const POST_ORDER_CANCEL_URL = `${API_URL}/order/cancel`
const POST_ORDER_SPEND_LIMIT_DEFAULT_URL = `${API_URL}/order/spend_limit_default`
const POST_DIGITAL_ASSET_URL = `${API_URL}/trxdigitalasset`
const POST_DIGITAL_ASSET_APPROVE_URL = `${API_URL}/trxdigitalasset/approve`
const POST_DIGITAL_ASSET_TRANSFERRING_URL = `${API_URL}/trxdigitalasset/transferring`
const POST_DIGITAL_ASSET_TRANSFERRING_CANCEL_URL = `${API_URL}/trxdigitalasset/transfer_cancel`
const POST_DIGITAL_ASSET_TRANSFER_FINISH_URL = `${API_URL}/trxdigitalasset/transfer_finish`
const POST_DIGITAL_ASSET_FINISH_URL = `${API_URL}/trxdigitalasset/finish`
const POST_DIGITAL_ASSET_CANCEL_URL = `${API_URL}/trxdigitalasset/cancel`
const POST_DIGITAL_ASSET_AUDIT_URL = `${API_URL}/trxdigitalasset/audit`
const GET_ASSET_PRICE = `${API_URL}/price`
const GET_ORDER_LIST_URL = `${API_URL}/order/full`
const POST_BITGO_SEND_URL = `${API_URL}/bitgo/send`

const useUserPermissionAdmin = (email?: string | null) => {
  const result = useQuery(
    `${QUERIES.USER_PERMISSION_ADMIN}`,
    () => {
      return getUserPermissionAdmin(email)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return result
}

const useOccupationGroup = (queryKey: string) => {
  const result = useQuery(
    `${queryKey}`,
    () => {
      return getOccupationGroup()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return result
}

const useOccupationList = (queryKey: string, id?: number) => {
  const result = useQuery(
    `${queryKey}`,
    () => {
      return getOccupationList(id)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return result
}

const postFiatCreate = (body: DigitalAssetCreateRequest) => {
  return axios.post(`${POST_FIAT_CREATE_URL}`, body)
}

const trxFiatAudit = (id_trxfiat?: number, auditor?: string) => {
  return axios.post(`${POST_FIAT_AUDIT_URL}`, {
    id_trxfiat,
    auditor,
  })
}

const postFiatCancel = (id_trxfiat?: number, refcode?: string, cancel_reason?: string) => {
  return axios.post(`${POST_FIAT_CANCEL_URL}`, {
    id_trxfiat,
    refcode,
    cancel_reason: cancel_reason ?? 'Invalid Proof',
  })
}

const postFiatTransferring = (
  id_trxfiat?: number,
  payer?: string,
  payment_channel?: string,
  payment_autosettle?: boolean
) => {
  return axios.post(`${POST_FIAT_TRANSFERRING_URL}`, {
    id_trxfiat,
    payer,
    payment_channel,
    payment_autosettle,
  })
}

const postFiatApprove = (id_trxfiat?: number, approver?: string) => {
  return axios.post(`${POST_FIAT_APPROVE_URL}`, {
    id_trxfiat,
    approver,
  })
}

const postFiatTransferringCancel = (id_trxfiat?: number) => {
  return axios.post(`${POST_FIAT_TRANSFERRING_CANCEL_URL}`, {
    id_trxfiat,
  })
}

const postFiatTransferFinish = (
  id_trxfiat?: number,
  payer?: string,
  payment_account_src_actual?: string,
  payment_proof?: string,
  payment_value_actual?: number,
  payment_value_fee?: number,
  datetime_payment?: string
) => {
  return axios.post(`${POST_FIAT_TRANSFERRING_FINISH_URL}`, {
    id_trxfiat,
    payer,
    payment_proof,
    payment_value_actual,
    payment_value_fee,
    datetime_payment,
    payment_account_src_actual,
  })
}

const postFiatFinish = (id_trxfiat?: number) => {
  return axios.post(`${POST_FIAT_FINISH_URL}`, {
    id_trxfiat,
  })
}

const postOrderDigitaLassetPrice = (
  id_order?: number,
  digitalasset_price_actual?: number,
  datetime_payment?: string
) => {
  return axios.post(`${POST_ORDER_DIGITAL_ASSET_PRICE_URL}`, {
    id_order,
    digitalasset_price_actual,
    datetime_payment,
  })
}

const postOrderPending = (id_order?: number) => {
  return axios.post(`${POST_ORDER_PENDING_URL}`, {
    id_order,
  })
}

const postOrderFinish = (id_order?: number) => {
  return axios.post(`${POST_ORDER_FINISH_URL}`, {
    id_order,
  })
}

const postUpdateToreceive = (id_order?: number) => {
  return axios.post(`${POST_ORDER_UPDATE_TORECEIVE_URL}`, {
    id_order,
  })
}

const postOrderCancel = (id_order?: number, refcode?: string, cancel_reason?: string) => {
  return axios.post(`${POST_ORDER_CANCEL_URL}`, {
    id_order,
    refcode,
    cancel_reason: cancel_reason ?? 'Invalid Proof',
  })
}

const postOrderSpendLimitDefault = () => {
  return axios.get(`${POST_ORDER_SPEND_LIMIT_DEFAULT_URL}`)
}

const postDigitalAssetCreate = (body: DigitalAssetCreateRequest) => {
  return axios.post(`${POST_DIGITAL_ASSET_URL}`, body)
}

const postDigitalAssetApprove = (id_trxdigitalasset?: number, approver?: string) => {
  return axios.post(`${POST_DIGITAL_ASSET_APPROVE_URL}`, {
    id_trxdigitalasset,
    approver,
  })
}

const postDigitalAssetTransferring = (
  id_trxdigitalasset?: number,
  payer?: string,
  payment_channel?: string,
  payment_autosettle?: boolean
) => {
  return axios.post(`${POST_DIGITAL_ASSET_TRANSFERRING_URL}`, {
    id_trxdigitalasset,
    payer,
    payment_channel,
    payment_autosettle,
  })
}

const postDigitalAssetTransferringCancel = (id_trxdigitalasset?: number) => {
  return axios.post(`${POST_DIGITAL_ASSET_TRANSFERRING_CANCEL_URL}`, {
    id_trxdigitalasset,
  })
}

const postDigitalAssetTransferFinish = (
  id_trxdigitalasset?: number,
  payer?: string,
  payment_wallet_src_actual?: string,
  payment_proof?: string,
  payment_value_actual?: number,
  payment_value_fee?: number
) => {
  return axios.post(`${POST_DIGITAL_ASSET_TRANSFER_FINISH_URL}`, {
    id_trxdigitalasset,
    payer,
    payment_wallet_src_actual,
    payment_proof,
    payment_value_actual,
    payment_value_fee,
  })
}

const postDigitalAssetFinish = (id_trxdigitalasset?: number) => {
  return axios.post(`${POST_DIGITAL_ASSET_FINISH_URL}`, {
    id_trxdigitalasset,
  })
}

const postDigitalAssetCancel = (
  id_trxdigitalasset?: number,
  refcode?: string,
  cancel_reason?: string
) => {
  return axios.post(`${POST_DIGITAL_ASSET_CANCEL_URL}`, {
    id_trxdigitalasset,
    refcode,
    cancel_reason: cancel_reason ?? 'Invalid Proof',
  })
}

const trxDigitalAssetAudit = (id_trxdigitalasset?: number, auditor?: string) => {
  return axios.post(`${POST_DIGITAL_ASSET_AUDIT_URL}`, {
    id_trxdigitalasset,
    auditor,
  })
}

const getOrderFullById = (id: ID): Promise<Response<OrderFull>> => {
  return axios.get(`${GET_ORDER_LIST_URL}?id_order=${id}`)
}

const getAssetPrice = (symbol?: string, atTime?: string) => {
  return axios.get(`${GET_ASSET_PRICE}?symbol=${symbol}&at_time=${atTime}`)
}

const postBitgoSend = (data : BitgoSendRequest) => {
  return axios.post(`${POST_BITGO_SEND_URL}`, data)
}


const postSendMessage = (data: SendMessage) => {
  return axios.post(
    `${SMS_API_URL}/api/send-message`,
    {
      sender: '1109x',
      ...data,
    },
    {
      headers: {
        secret_key: '6fEQZUWHTbxrTBjK',
        api_key: '798b3f569546121cfc880ce3634059c0',
      },
    }
  )
}

export {
  useUserPermissionAdmin,
  useOccupationGroup,
  useOccupationList,
  postFiatCreate,
  trxFiatAudit,
  postFiatCancel,
  postFiatTransferring,
  postFiatApprove,
  postFiatTransferringCancel,
  postFiatTransferFinish,
  postFiatFinish,
  postOrderDigitaLassetPrice,
  postOrderPending,
  postOrderFinish,
  postUpdateToreceive,
  postOrderCancel,
  postOrderSpendLimitDefault,
  postDigitalAssetCreate,
  postDigitalAssetApprove,
  postDigitalAssetTransferring,
  postDigitalAssetTransferringCancel,
  postDigitalAssetTransferFinish,
  postDigitalAssetFinish,
  postDigitalAssetCancel,
  trxDigitalAssetAudit,
  getAssetPrice,
  getOrderFullById,
  postBitgoSend,
  postSendMessage,
}
