import {fireStoreFB1} from '../../firebase'
import {doc, getDoc, updateDoc} from 'firebase/firestore/lite'
import {ID} from '../helpers'
import {UserDataSensitiveResponse} from './_models'

const getUserDataSensitive = async (id: ID): Promise<UserDataSensitiveResponse> => {
  const docRef = doc(fireStoreFB1, `user_data_sensitive/${id}`)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    return {
      data: docSnap.data(),
    }
  } else {
    return {
      data: undefined,
    }
  }
}

const updateFirestoreByPath = async (path, data: any): Promise<void> => {
  const washingtonRef = doc(fireStoreFB1, path)
  return updateDoc(washingtonRef, data)
}

const updateUserDataSensitive = async (id: ID, data: any): Promise<void> => {
  return updateFirestoreByPath(`user_data_sensitive/${id}`, data)
}

export {getUserDataSensitive, updateUserDataSensitive}
