import {FC, ReactElement} from 'react'
import clsx from 'clsx'

type Props = {
  children: ReactElement
  label: string
  className?: string
  required?: boolean
  errors?: string
}

const InputWrapper: FC<Props> = ({children, className, label, required = false, errors}) => {
  return (
    <div className={className}>
      <label
        className={clsx('col-form-label fw-semibold fs-4 pt-10', {
          required: required,
        })}
      >
        {label}
      </label>
      {children}
      {Boolean(errors) && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{errors}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export {InputWrapper}
